import { Collapse, Slider, Row, Col, Spin, Tooltip, Button, Space } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import html2canvas from 'html2canvas';

// import { jsPDF } from "jspdf";
import { saveAs } from 'file-saver';
import "./index.scss";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { ProfileResponse } from "../../../Models/Responses/ProfileResponse";
import BenchmarkCostTypes from './Benchmarking/BenchmarkCostTypes';
import BenchmarkRDDisciplines from './Benchmarking/BenchmarkRDDisciplines';
import DisciplinesVSBestPractice from './Benchmarking/DisciplinesVSBestPractice';
import { IEbitToolState, IGeneralState } from "../../../Redux/reducers/IGeneralState";
import Waterfallv2 from "./BasicCharts/Waterfall-V2";
import { ActionTypes } from "../../../Redux/actionsTypes";
import { BmProject } from "../../../Models/Responses/ProfileResponse";
import { FileExcelTwoTone } from '@ant-design/icons';
import CostTypesBMImage from "../../../assets/Images/EbitFrontPAge/BenchmarkingCostTypes.png";
import AsupColumnChart from "./BasicCharts/ASupColumnchart"
import EvaluationPanelTopBar from "./EvaluationPanelTopBar";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
interface EvaluationTopLevelPanelProps {
    ebitResult: IEbitToolState;
    profileResponse: ProfileResponse;
    getEbitEvaluation: (projectID: number) => any;
    getRndInfo: any;
    //projects: BmProject[];
    getEbitBenchmark: (projectID: number) => any;
    downloadCostTypesExcel: (projectID: number, projectName: string) => any;
    downloadDisciplinesExcel: (projectID: number, projectName: string) => any;
    downloadDisciplinesVsBestPractice: (projectID: number, projectName: string) => any;
    downloadFullExcelReport: (projectID: number, projectName: string) => any;
    downloadPresentation: (projectID: number, projectName: string) => any;
}
function EvaluationTopLevelPanel(props: EvaluationTopLevelPanelProps) {
    const { t } = useTranslation();
    const [scale, setScale] = useState(1);

    let [showTopLevelCharts, setshowTopLevelCharts] = useState(true);
    let [showEvaluationCharts, setshowEvaluationCharts] = useState(false);
    let [showCostTypesCharts, setshowCostTypesCharts] = useState(false);
    let [showDiciplinesCharts, setshowDiciplinesCharts] = useState(false);
    let [showDiciplinesVsBestPracticeCharts, setshowDiciplinesVsBestPracticeCharts] = useState(false);

    const [selectedCharts, setSelectedCharts] = useState(new Array<string>());
    const [renderedCharts, setrenderedCharts] = useState(new Array<string>());

    const onChange = (value: number) => {
        setScale(value);
    };
    useEffect(() => {
        if (props.profileResponse?.currentProject) {
            props.getRndInfo(props.profileResponse.currentProject.id);
        }
    }, [props.profileResponse?.currentProject?.id]);

    useEffect(() => {
        if (props.profileResponse?.currentProject)
            props.getEbitEvaluation(props.profileResponse.currentProject.id);
    }, [props.profileResponse?.currentProject?.id, props.ebitResult.dProjectForEbit, props.ebitResult.rndInfoResponse]);

    useEffect(() => {
        if (props.profileResponse?.currentProject)
            props.getEbitBenchmark(props.profileResponse.currentProject.id);
    }, [props.profileResponse?.currentProject?.id, props.ebitResult.rndInfoResponse, props.ebitResult.dProjectForEbit]);

    const data =
        props.ebitResult?.evaluationInfo?.data?.map((p) => {
            return { Name: p.name, Cost: p.cost, Rate: p.rate };
        }) ?? [];
    const originalDisciplinesNames = props?.ebitResult?.rndInfoResponse?.disciplines?.filter(x => x.costTypeIsOriginalNotInterdisciplinary).map(x => x.code.toLowerCase());
    const originalDisciplinesEnglishNames = props?.ebitResult?.rndInfoResponse?.disciplines?.filter(x => x.costTypeIsOriginalNotInterdisciplinary).map(x => x.englishCode.toLowerCase());
    const DownloadFullReportExcel = async () => {
        props.downloadFullExcelReport(props?.profileResponse?.currentProject?.id, props?.profileResponse?.currentProject?.project_name);
    };
    const DownloadPresentation = async () => {
        props.downloadPresentation(props?.profileResponse?.currentProject?.id, props?.profileResponse?.currentProject?.project_name);
    };
    const DownloadCostTypesExcel = async () => {
        props.downloadCostTypesExcel(props?.profileResponse?.currentProject?.id, props?.profileResponse?.currentProject?.project_name);
    };
    const DownloadDisciplinesExcel = async () => {
        props.downloadDisciplinesExcel(props?.profileResponse?.currentProject?.id, props?.profileResponse?.currentProject?.project_name);
    };

    const DownloadDiscsVsBPsExcel = async () => {
        props.downloadDisciplinesVsBestPractice(props?.profileResponse?.currentProject?.id, props?.profileResponse?.currentProject?.project_name);
    };
    const totalRndBP = () => { return props.ebitResult?.ebitBenchmarkingInfo?.disciplinesBestPractices?.find(x => x.name == "Gesamte F&E-Kosten") };
    const totalRndDiscipline = () => {
        // let projectsCountPerBranch = {};
        return props.ebitResult?.ebitBenchmarkingInfo?.projectsBenchmarks?.map((d) => {
            let item = d.projectDisciplinesBenchmark?.find(x => x.name == "Gesamte F&E-Kosten");
            // if (!d.isTarget)
            //     projectsCountPerBranch[d.annoName] = (projectsCountPerBranch[d.annoName] || 0) + 1;
            return {
                name: d.projectName,
                // annoName: d.annoName.substring(0, 1).toUpperCase() + projectsCountPerBranch[d.annoName],
                value: item?.rate,
                isTarget: d.isTarget,
                isComplete: d.isComplete
            }
        }
        );
    }
    const SummaryDisciplinesNames = [t("Gesamte F&E-Kosten"), t("Interdisziplinäre F&E Kosten"), t("Originäre F&E Kosten")];
    const CODBP = () => { return props.ebitResult?.ebitBenchmarkingInfo?.costTypesBestPractices?.codbp };
    const GetChartDataByCostType = (costType: string) => {
        // let projectsCountPerBranch = {};
        let ebitBMProjectsForChart = props.ebitResult?.ebitBenchmarkingInfo?.projectsBenchmarks?.map((x) => {
            // if (!x.isTarget)
            //     projectsCountPerBranch[x.annoName] = (projectsCountPerBranch[x.annoName] || 0) + 1;
            return {
                name: x.projectName,
                // annoName: x.annoName.substring(0, 1).toUpperCase() + projectsCountPerBranch[x.annoName],
                value: x.costTypes ? x.costTypes[costType] : 0,
                isTarget: x.isTarget,
                isComplete: x.isComplete,
            }
        });
        return ebitBMProjectsForChart;
    }
    const backToTopLvel = () => {
        // setSelectedCharts([]);
        // setrenderedChartsDetailsAndValues([]);
        setshowTopLevelCharts(true);
        setshowCostTypesCharts(false);
        setshowDiciplinesCharts(false);
        setshowDiciplinesVsBestPracticeCharts(false);
        setshowEvaluationCharts(false);
        setrenderedCharts([]);
    };
    const GotoEvaluationCharts = () => {
        setshowTopLevelCharts(false);
        setshowCostTypesCharts(false);
        setshowDiciplinesCharts(false);
        setshowDiciplinesVsBestPracticeCharts(false);
        setshowEvaluationCharts(true);
        setrenderedCharts([]);
    }
    const GotoCostTypesCharts = (e) => {
        let elementClass = e.target.className.baseVal;
        if (elementClass != '' && !elementClass?.includes('brush')) {
            // console.log(elementClass.baseVal);

            setshowTopLevelCharts(false);
            setshowCostTypesCharts(true);
            setshowDiciplinesCharts(false);
            setshowDiciplinesVsBestPracticeCharts(false);
            setshowEvaluationCharts(false);
            setrenderedCharts(['Cost of Delay EBIT-Ratio Potential [%]',
                'Quality Cost EBIT-Ratio Potential [%]',
                'Manufacturing Costs EBIT-Ratio Potential [%]',
                'Total Innovation / sales [%]',
                'Innovation Portfolio / sales [%]',
                'Innovation substitutions / sales [%]',
            ]);
        }
    }
    const GotoDiciplinesCharts = (e) => {
        let elementClass = e.target.className.baseVal;
        if (elementClass != '' && !elementClass?.includes('brush')) {
            setshowTopLevelCharts(false);
            setshowCostTypesCharts(false);
            setshowDiciplinesCharts(true);
            setshowDiciplinesVsBestPracticeCharts(false);
            setshowEvaluationCharts(false);
            const AllDisciplinesNames = props?.ebitResult?.ebitBenchmarkingInfo?.disciplinesBestPractices?.map(d => i18n.language === 'de' ? d.name : d.englishName);
            let chartNames = AllDisciplinesNames?.map(name => {
                if (!SummaryDisciplinesNames.includes(name)) {
                    return `${name} / ${t("Gesamte F&E-Kosten")}`
                }
                else {
                    return `${name} / ${t("Umsatz")}`
                }
            });
            // setrenderedCharts(chartNames);
            setrenderedCharts(prevrenderedCharts => ([...chartNames]));

        }

    }
    const GotoDiciplinesVsBestPracticeCharts = () => {
        setshowTopLevelCharts(false);
        setshowCostTypesCharts(false);
        setshowDiciplinesCharts(false);
        setshowDiciplinesVsBestPracticeCharts(true);
        setshowEvaluationCharts(false);
        setrenderedCharts(["Distribution of Original R&D-Costs",
            "Distribution of Interdisciplinary R&D-Costs"]);
    }
    const selectChart = (divId: string, add: boolean) => {
        if (!divId) return;
        setSelectedCharts((selectedCharts) => {
            let newSelectedCharts = [...selectedCharts];
            if (add)
                newSelectedCharts.push(divId);
            else
                newSelectedCharts = newSelectedCharts.filter(sc => sc !== divId);
            return newSelectedCharts;
        });
    };
    const finishedDownload = () => {
        setSelectedCharts([]);
    };
    const DownloadAllChartsAsImage = async () => {
        selectedCharts.forEach(async selectedChart => {
            DownloadChartAsImage(selectedChart, showDiciplinesVsBestPracticeCharts);
        });
    };
    const GetExportFunction = () => {
        if (showCostTypesCharts)
            return DownloadCostTypesExcel();
        else if (showDiciplinesCharts)
            return DownloadDisciplinesExcel();
        else if (showDiciplinesVsBestPracticeCharts)
            return DownloadDiscsVsBPsExcel();
        else if (showEvaluationCharts)
            return DownloadFullReportExcel();
        else if (showTopLevelCharts)
            return DownloadPresentation();

    }
    const DownloadChartAsImage = async (subTitle, isStacked: boolean) => {
        const canvasSave = document.getElementById(subTitle);
        var lastChild;
        var subcanvasSave;
        if (!isStacked) {
            subcanvasSave = document.getElementById(subTitle + "_chart");

            lastChild = subcanvasSave.lastElementChild;
            // if (this.props.ebitBMProjectsForChart?.length >= 20) {
            //     if (subcanvasSave.hasChildNodes()) {
            //         subcanvasSave.removeChild(lastChild);
            //     }
            // }
            canvasSave.insertAdjacentHTML('afterbegin', `<div id="appendid" style="text-align: center; font-weight:bold;" }>
        <text>${subTitle}</text></div>`);
        }
        let heightToBeAdded = document.getElementById("appendid").offsetHeight * 2;
        let originalHeight = canvasSave.offsetHeight;
        let newHeight = heightToBeAdded + originalHeight;
        html2canvas(canvasSave, { height: newHeight, scale: 20 })
            .then((canvas) => {
                canvas.toBlob(function (blob) {
                    saveAs(blob, `${subTitle}.png`)
                })
            })
        if (!isStacked) {
            // if (this.props.ebitBMProjectsForChart?.length >= 20) {
            //     subcanvasSave.appendChild(lastChild);
            // }
            canvasSave.removeChild(canvasSave.firstChild);
        }
    }

    const ResizeCanvas = (canvas, newWidth, newHeight) => {
        var tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = newWidth;
        tmpCanvas.height = newHeight;

        var ctx = tmpCanvas.getContext('2d');
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

        return tmpCanvas;
    }
    const onCheckAllChange = (checked: boolean) => {
        if (!checked) {
            setSelectedCharts([]);
        } else {
            setSelectedCharts(renderedCharts);
        }
    };
    return (
        <>
            <EvaluationPanelTopBar
                selectedCharts={selectedCharts}
                isCheckAllVisible={!showTopLevelCharts}
                // finishedDownload={finishedDownload}
                checkAll={renderedCharts.length === selectedCharts.length}
                onCheckAllChange={onCheckAllChange}
                DownloadAllChartsAsImage={DownloadAllChartsAsImage}
                backBtnTitle={!showTopLevelCharts ? 'Back to Top Charts' : ''}
                isBackBtnVisible={!showTopLevelCharts}
                goBack={!showTopLevelCharts ? backToTopLvel : null}
                exportChartsToExcel={GetExportFunction}
            />
            <Spin spinning={props.ebitResult.isEbitLoading}>
                {showTopLevelCharts && <>


                    <div className="TopLevelchartContainer unselectable" style={{ height: '28vh', paddingTop: '0vh', width: '100%' }}>
                        <Row style={{ overflow: 'hidden' }}>
                            <Col span={1}></Col>
                            <Col span={10} style={{ cursor: 'pointer', border: '1px solid #ccc' }}>
                                <div onClick={(e) => GotoEvaluationCharts()}
                                    title="Click to show Evaluation Charts"
                                >
                                    <Row style={{ fontWeight: "bold", textAlign: "center" }}>
                                        <Col span={24}>
                                            <div >
                                                <text dominantBaseline="hanging" textAnchor='middle' fontSize="13" fontWeight="bold">{t("EBIT Potenzial")} [in %]</text>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Waterfallv2
                                        key={`rate_waterfall_chart`}
                                        chartTitle="EBIT potential"
                                        // totalSale={props.ebitResult?.evaluationInfo?.ebitSales ?? 0}
                                        type="Rate"
                                        data={data}
                                        scale={7}
                                        isTopLevel={true}
                                    />
                                </div>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={11} >
                                <div onClick={(e) => GotoCostTypesCharts(e)}
                                    title="Click to show Cost Types Charts"
                                    style={{ cursor: 'pointer', border: '1px solid #ccc' }}>
                                    <Row style={{ fontWeight: "bold", textAlign: "center" }}>
                                        <Col span={24}>
                                            <div >
                                                <text dominantBaseline="hanging" textAnchor='middle' fontSize="13" fontWeight="bold">Benchmarking Cost Types</text>
                                            </div>
                                        </Col>
                                    </Row>
                                    <AsupColumnChart
                                        isTopLevelChart={true}
                                        key={'Cost of Delay'}
                                        subTitle={'Cost of Delay EBIT-Ratio Potential [%]'}
                                        ebitBMProjectsForChart={GetChartDataByCostType('codRate')}
                                        bestPractice={CODBP()}
                                    />
                                </div>
                            </Col>
                            <Col span={1}></Col>
                        </Row>
                    </div>
                    <div className="TopLevelchartContainer unselectable" style={{ height: '35vh', paddingTop: '4vh', width: '100%' }}>
                        <Row style={{ overflow: 'hidden' }}>
                            <Col span={1}></Col>
                            <Col span={10} >
                                <div onClick={(e) => GotoDiciplinesCharts(e)}
                                    title="Click to show Disciplines Charts"
                                    style={{ cursor: 'pointer', border: '1px solid #ccc', paddingRight: '1vh' }}>
                                    <Row style={{ fontWeight: "bold", textAlign: "center" }}>
                                        <Col span={24}>
                                            <div >
                                                <text dominantBaseline="hanging" textAnchor='middle' fontSize="13" fontWeight="bold">Benchmarking R&D</text>
                                            </div>
                                        </Col>
                                    </Row>
                                    <AsupColumnChart
                                        isTopLevelChart={true}
                                        key={'Total R&D'}
                                        subTitle={`${t("Gesamte F&E-Kosten")} / ${t("Umsatz")}`}
                                        ebitBMProjectsForChart={totalRndDiscipline()}
                                        bestPractice={{
                                            value: totalRndBP()?.bestPractice?.value ?? 0,
                                            max: totalRndBP()?.bestPractice?.max ?? 0,
                                            min: totalRndBP()?.bestPractice?.min ?? 0,
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={11} >
                                <div
                                    onClick={(e) => GotoDiciplinesVsBestPracticeCharts()}
                                    title="Click to show Cost Types Charts"
                                    style={{ cursor: 'pointer', border: '1px solid #ccc' }}>

                                    <Row style={{ fontWeight: "bold", textAlign: "center" }}>
                                        <Col span={24}>
                                            <div >
                                                <text dominantBaseline="hanging" textAnchor='middle' fontSize="13" fontWeight="bold">Disciplines vs Best Practices</text>
                                            </div>
                                        </Col>
                                    </Row>
                                    <DisciplinesVSBestPractice
                                        selectedCharts={selectedCharts}
                                        isTopLevelChart={true}
                                        projectName={props.profileResponse?.currentProject?.shortName}
                                        ebitBenchmarkingInfo={props.ebitResult?.ebitBenchmarkingInfo}
                                        OriginalDisciplines={originalDisciplinesNames}
                                        originalDisciplinesEnglish={originalDisciplinesEnglishNames}
                                    />
                                </div>
                            </Col>
                            <Col span={1}></Col>
                        </Row>
                    </div>
                </>
                }
                {showEvaluationCharts &&
                    <>
                        <div className="chart_container">
                            <Row>
                                <Col span={12}>
                                    <Waterfallv2
                                        key={`rate_waterfall_chart`}
                                        chartTitle={t("EBIT Potenzial")}
                                        // totalSale={props.ebitResult?.evaluationInfo?.ebitSales ?? 0}
                                        type="Rate"
                                        data={data}
                                        scale={scale}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Waterfallv2
                                        key={`cost_waterfall_chart`}
                                        chartTitle={t("EBIT Potenzial")}
                                        // totalSale={props.ebitResult?.evaluationInfo?.ebitSales ?? 0}
                                        type="Cost"
                                        data={data}
                                        scale={scale}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Row align="middle">
                            <Slider
                                style={{ width: "50%", margin: "10px auto" }}
                                defaultValue={1}
                                onChange={onChange}
                            />
                        </Row>
                    </>
                }
                {showCostTypesCharts &&
                    <BenchmarkCostTypes
                        selectedCharts={selectedCharts}
                        selectChart={selectChart}
                        CostTypesBestPractices={props.ebitResult?.ebitBenchmarkingInfo?.costTypesBestPractices}
                        projectsBenchmarks={props.ebitResult?.ebitBenchmarkingInfo?.projectsBenchmarks}
                    />
                }
                {showDiciplinesCharts &&
                    <BenchmarkRDDisciplines
                        selectedCharts={selectedCharts}
                        selectChart={selectChart}
                        ebitBenchmarkingInfo={props.ebitResult?.ebitBenchmarkingInfo}
                    />
                }
                {showDiciplinesVsBestPracticeCharts &&
                    <DisciplinesVSBestPractice
                        selectedCharts={selectedCharts}
                        selectChart={selectChart}
                        isTopLevelChart={false}
                        projectName={props.profileResponse?.currentProject?.shortName}
                        ebitBenchmarkingInfo={props.ebitResult?.ebitBenchmarkingInfo}
                        OriginalDisciplines={originalDisciplinesNames}
                        originalDisciplinesEnglish={originalDisciplinesEnglishNames}
                    />
                }
            </Spin >
        </>
    );
}
const mapStateToProps = (state: IGeneralState) => {
    return {
        ebitResult: state.ebit,
        profileResponse: state.generalInfo.profileResponse,
        //projects: state.generalInfo.allProjects,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getRndInfo: (input: any) => dispatch({ type: ActionTypes.GetRndInfo, projectID: input }),
        getEbitEvaluation: (projectID: number) =>
            dispatch({ type: ActionTypes.GetEbitEvaluation, projectID: projectID }),
        getEbitBenchmark: (projectID: number) =>
            dispatch({ type: ActionTypes.GetEbitBenchmark, projectID: projectID }),
        downloadCostTypesExcel: (projectID: number, projectName: string) =>
            dispatch({ type: ActionTypes.DownloadCostTypesExcel, projectID, projectName }),
        downloadDisciplinesVsBestPractice: (projectID: number, projectName: string) =>
            dispatch({ type: ActionTypes.DownloadDisciplinesVsBestPractice, projectID, projectName }),
        downloadDisciplinesExcel: (projectID: number, projectName: string) =>
            dispatch({ type: ActionTypes.DownloadDisciplinesExcel, projectID, projectName }),
        downloadFullExcelReport: (projectID: number, projectName: string) =>
            dispatch({ type: ActionTypes.DownloadFullExcelReport, projectID, projectName }),
        downloadPresentation: (projectID: number, projectName: string) =>
            dispatch({ type: ActionTypes.DownloadPresentation, projectID, projectName }),
    };


};

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationTopLevelPanel);
