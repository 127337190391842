import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Area, Bar, BarChart, CartesianGrid, Cell, ComposedChart, Label, LabelList, Legend, Line, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { RTDCurveData } from "../Models/RTDCurveData";
import "./rtdcurve.scss";
import { IFrontloadingnitialState, IGeneralState } from "../../../../../../Redux/reducers/IGeneralState";
import moment from "moment";
import i18n from "../../../../../../i18n";

interface IProps {
    RTDCurveData: RTDCurveData;
    withLegend?: boolean;
    frontLoadingProjects: IFrontloadingnitialState;
    showBestPracticeCurve: boolean;
}

function RTDCurve(props: IProps) {

    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const [maxValue, setMaxValue] = useState(1);
    const [maxFteValue, setMaxFteValue] = useState(1);
    const [rangeAxis, setRangeAxis] = useState([0, 1]);
    useEffect(() => {
        if (props.RTDCurveData) {
            //console.log("props.RTDCurveData, ", props.RTDCurveData);
            let fteNumbers: number[] = [];
            props.RTDCurveData?.departmentsFtesAtTimePoints?.map(d => {
                fteNumbers.push(Math.max(d.departmentsFtes.map(c => c.fteValue).reduce((c, t) => t = t + c, 0)));
            });
            fteNumbers.push(Math.max(...props.RTDCurveData.bestPracticesAtTimePoints.map(c => c.fteValue)));
            let maxFteVal = Math.round(Math.max(...fteNumbers) + 5);
            setMaxFteValue(maxFteVal + 5);

            let data = props.RTDCurveData?.departmentsFtesAtTimePoints?.map(d => {
                let ftesObject = {};
                d.departmentsFtes.forEach(fte => {
                    ftesObject[fte.departmentName] = fte.fteValue;
                });
                return {
                    timePoint: d.timePoint,
                    ...ftesObject,
                    bestPracticeValue: null,
                    maxFteVal: null,
                    mileStoneLabel: null
                }
            });

            props.RTDCurveData?.milestones?.map((mm, mi) => {
                data.push({
                    timePoint: mm.timePoint,
                    bestPracticeValue: null,
                    maxFteVal: (mi % 2 == 0) ? maxFteVal * 0.8 : maxFteVal,  //mm.fteValue,
                    mileStoneLabel: mm.name
                });
            });
            let numbers: number[] = data.map(c => { return c.timePoint; });
            let maxVal = Math.max(...numbers);
            setMaxValue(maxVal);
            let step = Math.round(100 * (maxVal / 30)) / 100;
            let steps = [];
            for (let i = 0; i + step <= maxVal; i = i + step) {
                steps.push(i);
            }
            console.log("steps", steps);
            steps.push(maxVal);
            setRangeAxis(steps);
            let ftesObject = {};
            props.RTDCurveData.flDepartments.forEach(d => {
                ftesObject[d.name] = null;
            });
            props.RTDCurveData.bestPracticesAtTimePoints.forEach(b => {
                data.push({
                    timePoint: b.timePoint,
                    ...ftesObject,
                    bestPracticeValue: b.fteValue,
                    maxFteVal: null,
                    mileStoneLabel: null
                });
            });
            let ftesObject_ = {};
            props.RTDCurveData.flDepartments.forEach(d => {
                ftesObject_[d.name] = 0;
            });
            data.push({
                timePoint: 0,
                ...ftesObject_,
                bestPracticeValue: 0,
                maxFteVal: null,
                mileStoneLabel: null
            });
            data.sort((a, b) => (a.timePoint > b.timePoint) ? 1 : -1);
            setData(data);
        }

    }, [props.RTDCurveData]);

    let legend = props.withLegend ? <Legend
        formatter={(value, entry) => {
            //console.log("Value, entry", value, entry);
            if (value == "maxFteVal") return `${t("Mile Stones")}`;
            if (value == "bestPracticeValue") return `${t("Best Practice Curve")}`;
            return value;
        }}
        layout='vertical'
        wrapperStyle={{ top: 0, left: 0 }}
        iconType='square'
        iconSize={10}
        align='left'
        verticalAlign='top' /> : null;
    console.log("RTDCurve data", data);
    return (
        <ResponsiveContainer className="RTDCurve-ResponsiveContainer" width="100%" height="100%">
            <ComposedChart data={data}

                margin={{ top: 50, right: 50, left: 25, bottom: 5 }}>

                <XAxis
                    /* id="area_id" */
                    dataKey="timePoint"
                    //allowDuplicatedCategory={false}
                    //tickCount={5}                    
                    type="number"
                    domain={[0, maxValue]}
                    ticks={rangeAxis}
                    tickFormatter={(val, index) => {
                        let latestDateToFocus = moment(props.frontLoadingProjects?.currentFlProject?.startDate);

                        return latestDateToFocus.add(val * 30, 'days').format(i18n.language === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY");
                    }}
                    label={{
                        value: t("Duration In Months"),
                        fontWeight: 600,
                        fill: '#1644b0',
                        position: "bottom",
                        offset: -5
                    }}
                />
                <YAxis
                    domain={[0, maxFteValue]}
                    label={{
                        value: t("Number Of Employees"),
                        angle: -90,
                        offset: -10,
                        fontWeight: 600,
                        fill: '#1644b0',
                    }}
                />
                <CartesianGrid strokeDasharray="3 3" />
                {legend}
                <Tooltip
                    itemSorter={(item) => {
                        if (item.name == "bestPracticeValue") return 0;
                        if (item.name == "maxFteVal") return 0;
                        let dep = props.RTDCurveData?.flDepartments.filter(c => c.name == item.name)?.[0];
                        //console.log("itemSorter", item.name, dep);
                        return dep.order;
                    }}
                    formatter={
                        (value, name, props) => {
                            //console.log("formatter (value, name, props)", value, name, props);
                            let tempName = name;
                            if (name == "maxFteVal") tempName = `${t("Mile Stones")}`;
                            if (name == "bestPracticeValue") tempName = `${t("Best Practice Curve")}`;
                            return [value, tempName]
                        }
                    }
                    labelFormatter={(label, payload) => {
                        //console.log("labelFormatter ", label, payload);
                        let tempName = payload?.[0]?.name;
                        if (tempName == "maxFteVal") return props.RTDCurveData.milestones?.filter(c => c.timePoint == label)?.[0]?.timePointLabel;
                        if (tempName == "bestPracticeValue") {
                            let tt = props.RTDCurveData.bestPracticesAtTimePoints?.filter(c => c.timePoint == label)?.[0];
                            return tt?.phaseName + ": " + tt?.timePointLabel;
                        }
                        return props.RTDCurveData.departmentsFtesAtTimePoints?.filter(c => c.timePoint == label)?.[0]?.timePointLabel;
                        //let latestDateToFocus = moment(props.frontLoadingProjects?.currentFlProject?.startDate);
                        //return latestDateToFocus.add(label * 30, 'days').format(i18n.language === "de" ? "DD.MM.YYYY" : "MM/DD/YYYY");
                    }}
                />
                {
                    props.RTDCurveData?.flDepartments.sort((a, b) => { return b.order - a.order }).map((department, idx) => {
                        return <Area key={"area_chart" + idx.toString()} type="monotone" connectNulls dataKey={department.name} stackId="1" stroke={department.backGroundColor} fillOpacity={0.8} fill={department.backGroundColor} />
                    })
                }
                <Bar dataKey="maxFteVal" barSize={2} fill="#000000" >
                    <LabelList dataKey="mileStoneLabel" position="top" offset={10} fill="#000000" />
                </Bar>
                {(props.showBestPracticeCurve) ?
                    <Line type="monotone" connectNulls dataKey="bestPracticeValue" stroke="red" name={`${t("bestPracticeValue")}`} /> :
                    null}

            </ComposedChart>
        </ResponsiveContainer>
    );
}

const getPath = (x, y, width, height, mileStoneLabel) => {
    return ` M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
    Z`;
};

const TriangleBar = (props) => {
    console.log("TriangleBar ", props);
    const { fill, x, y, width, height, mileStoneLabel } = props;
    return <path d={getPath(x, y, width, height, mileStoneLabel)} stroke="none" fill={fill} />;
};

const mapStateToProps = (state: IGeneralState) => {
    return {
        frontLoadingProjects: state.frontLoadingProjects
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RTDCurve);